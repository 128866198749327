<template>
  <div>
    <Panel header="Estructura de base de datos" :toggleable="true">
        <p>Para tener una organización en la base de datos se deben de seguir los siguientes puntos para la estructura de las tablas:</p>
        <ul>
          <li>Las nomenclaturas serán en inglés, así mismo, evitar usar nombres otro idioma.</li>
        </ul>
        <p>usuario -> <b>user</b></p>
        <ul>
          <li>Para nombrar a una tabla o a un campo, usar adjetivos singulares.</li>
        </ul>
        <p>users -> <b>user</b></p>
        <ul>
          <li>
            Si el nombre es compuesto por mas de una palabra, usar guion bajo para unir las palabras, usar solamente minúsculas
          </li>
        </ul>
        <p>
          user-access -> <b>user_access</b>
        </p>
        <ul>
          <li>
            Las PK de las tablas deben llamarse “id” 
          </li>
        </ul>
        <p>intUser -> <b>id</b></p>
        <ul>
          <li>
            Las FK deben de empezar con id seguido de un “_” y al final el nombre de la tabla
          </li>
        </ul>
        <p>intCompany -> <b>id_company</b></p>
        <ul>
          <li>
            Todas las tablas deben de llevar estos cuatro campos de auditoria
          </li>
        </ul>
        <p>
              created <b>DATETIME</b>,<br>
              created_by <b>VARCHAR(100)</b>,<br>
              modified <b>DATETIME</b>,<br>
              modified_by <b>VARCHAR(100)</b> <br>

        </p>
        <ul>
          <li>
            Los campos de fecha deberán de llevar “_date” al final, también utilizar DATE cuando solo necesitemos fecha y DATETIME si ocupamos tiempo
          </li>
        </ul>
        <p>
          expiration -> <b>expiration_date</b>
        </p>
        <ul>
          <li>
            Utilizar BIT para datos boléanos, así mismo poner “is_” como prefijo.
          </li>
        </ul>
        <p>
          active -> <b>is_active</b>
        </p>
        <ul>
          <li>
            Para agregar una regla de PK se debe de seguir lo siguiente
          </li>
        </ul>
        <p>
          ALTER TABLE tm_ticket_tag ADD CONSTRAINT pk_tm_ticket_tag PRIMARY KEY (id) <br> <br>
          El nombre de la regla será con prefijo “pk_” 
        </p>
        <ul>
          <li>
            Para agregar una regla de PK se debe de seguir lo siguiente
          </li>
        </ul>
        <p>ALTER TABLE tm_ticket <br>
            ADD CONSTRAINT fk_tm_ticket_to_id_tm_ticket_tag <br>
            FOREIGN KEY (id_tm_ticket_tag) REFERENCES tm_ticket_tag(id); <br> <br>
            El nombre de la regla será con prefijo “fk_” luego la tabla la cual alteramos, despues “_to_” y al final el campo que haremos referencia</p>
        <p>Ejemplo completo:</p>
        <div class="center" >
          <Image :src="'/images/developer/td1.png'" alt="Image" width="400" preview />
        </div>
    </Panel>
  </div>
</template>

<script>
export default {
  name: 'TutorialDatabase'
}
</script>

<style>
  .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>