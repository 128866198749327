<template>
    <div class="p-grid">
        <div class="p-col-12">
            <Menubar :model="items">
                <template #end>
                    <InputText placeholder="Search" type="text" />
                </template>
            </Menubar>
        </div>
        <div class="p-col-12">
            <keep-alive>
                <component v-bind:is="selectedComponent"></component>
            </keep-alive>
        </div>
  </div>
</template>

<script>
import Tutorial from '../../../components/developer/TutorialDatabase.vue';
import Documentation from '../../../components/developer/DatabaseDocumentation.vue';

export default {
    components: {
        'Tutorial': Tutorial,
        'Documentation': Documentation
    },
    data() {
        return {
            selectedComponent: '',
            items: [
                    {
                        label:'Tutorial',
                        icon:'pi pi-fw pi-file',
                        command: () => this.selected('Tutorial')
                    },
                    {
                        label:'Tablas',
                        icon:'pi pi-fw pi-file',
                        command: () => this.selected('Documentation')
                    },
                    {
                        label:'Funciones / SP',
                        icon:'pi pi-fw pi-file',
                        command: () => this.selected('Function')
                    }
                ]
        }
    },
    methods: {
        selected(data) {
            this.selectedComponent = data;
        }
    }
}
</script>

<style>

</style>